import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  FetchVehiclesTypesQuery,
  FetchVehiclesTypesQueryVariables,
} from '@customer-booking/__generated__/graphql';

export const FETCH_VEHICLES_TYPES = gql(/* GraphQL */ `
  query FetchVehiclesTypes(
    $pickUp: GeoPointInput!
    $rideRequestType: RideRequestType!
    $dropOff: GeoPointInput
    $includeOptions: Boolean!
  ) {
    saasCompany {
      vehicleTypes(
        pickUp: $pickUp
        rideRequestType: $rideRequestType
        dropOff: $dropOff
      ) {
        maxPlaces
        maxLuggages
        logo
        id
        name
        designation
        tagline
        options @include(if: $includeOptions) {
          id
          name
          checkedByDefault
          price
          currency {
            name
            symbol
          }
        }
      }
    }
  }
`);

export const useFetchVehiclesTypes = (
  variables?: FetchVehiclesTypesQueryVariables,
  options?: Omit<
    QueryHookOptions<FetchVehiclesTypesQuery, FetchVehiclesTypesQueryVariables>,
    'variables'
  >
) => {
  return useQuery(FETCH_VEHICLES_TYPES, {
    ...options,
    variables,
  });
};
