import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  FetchPlaceDetailsFromLatLongQuery,
  FetchPlaceDetailsFromLatLongQueryVariables,
} from '@customer-booking/__generated__/graphql';

export const FETCH_PLACE_DETAIL_FROM_LAT_LONG = gql(/* GraphQL */ `
  query FetchPlaceDetailsFromLatLong($input: LatLong!) {
    place {
      detailsFromLatLong(input: $input) {
        id
        name
      }
    }
  }
`);

export const useFetchPlaceDetailsFromLatLong = (
  variables?: FetchPlaceDetailsFromLatLongQueryVariables,
  options?: Omit<
    QueryHookOptions<
      FetchPlaceDetailsFromLatLongQuery,
      FetchPlaceDetailsFromLatLongQueryVariables
    >,
    'variables'
  >
) => {
  return useLazyQuery(FETCH_PLACE_DETAIL_FROM_LAT_LONG, {
    ...options,
    nextFetchPolicy: 'cache-first',
    variables,
  });
};
