import { CalendarDate, Time } from '@internationalized/date';
import { Address } from '../../types';

export const getLatLong = (
  address: Address
): {
  lat: number;
  long: number;
} => {
  return {
    lat: address.lat,
    long: address.long,
  };
};

export const getISOPickUpDate = (
  date: CalendarDate,
  time: Time
): string | undefined =>
  new Date(`${date.toString()}T${time.toString()}Z`).toISOString();
