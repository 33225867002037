import * as React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ZodError } from 'zod';
import { twJoin } from 'tailwind-merge';

import { TrainInput } from '@customer-booking/features/flights/components/TrainInput';
import { useMapStore } from '@customer-booking/features/map';
import { useFetchPlaceDetails } from '@customer-booking/features/places/api';
import { PlusIcon, ReverseIcon, XMarkIcon } from '@hermes/icons/simple';
import { Button, Field, IconButton, Tooltip } from '@hermes/ui';

import {
  Address as FavoriteAddressType,
  PlaceAutocompleteValue,
  PlacePicker,
} from './PlacePicker';
import { FlightControl } from './FlightControl';
import { RideFormValues } from './RideForm.types';
import { Address } from '../../types';
import { tripSchema } from './rideSchema';

const MAX_STOPS = 3;

const Bullet = ({
  children,
  onRemove,
}: {
  children?: React.ReactNode;
  onRemove?: () => void;
}) => {
  const className =
    'border-2 border-white shadow-lg rounded-full ring-2 ring-primary';

  return children && onRemove ? (
    <button
      className={twJoin(
        className,
        'bg-white size-5 hover:shadow-lg z-10 hover:ring-primary-dark hover:bg-gray-100 hover:border-gray-100 transition-all duration-300 hover:scale-125 active:scale-110 focus-ring-visible'
      )}
      onClick={onRemove}
      type="button"
    >
      {children}
    </button>
  ) : (
    <div className={twJoin(className, 'size-4 mx-0.5 bg-primary')} />
  );
};

const Stop = ({
  children,
  first,
  last,
  onRemove,
  noBullet = false,
}: {
  children: React.ReactNode;
  first?: boolean;
  last?: boolean;
  onRemove?: () => void;
  noBullet?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const isIntermediateStop = !noBullet && !first && !last;

  return (
    <div className="flex space-x-1 items-stretch">
      <div className="mx-2 flex flex-col items-center">
        <div className={twJoin('h-full w-2 -mt-2', !first && 'bg-primary')} />
        {noBullet ? (
          <div className="w-5" />
        ) : isIntermediateStop ? (
          <Bullet
            onRemove={onRemove}
            aria-label={formatMessage({
              description: 'Remove stop button label',
              defaultMessage: 'Remove stop',
            })}
          >
            <XMarkIcon className="size-4 fill-primary stroke-primary" />
          </Bullet>
        ) : (
          <Bullet />
        )}
        <div className={twJoin('h-full w-2 -mb-2', !last && 'bg-primary')} />
      </div>
      {children}
    </div>
  );
};

export const LegsFields = ({
  onChange,
  additionalFavoritesAddresses = [],
}: {
  onChange?: (trip: {
    pickUpAddress: PlaceAutocompleteValue | null;
    dropOffAddress: PlaceAutocompleteValue | null;
    stops: PlaceAutocompleteValue[] | null;
  }) => void;
  additionalFavoritesAddresses?: FavoriteAddressType[];
}) => {
  const { formatMessage } = useIntl();

  const {
    control,
    setValue,
    getValues,
    formState,
    setError,
    clearErrors,
    trigger,
  } = useFormContext<RideFormValues>();

  const { fields, append, remove } = useFieldArray({
    name: 'trip.stops',
  });

  const pickUpAddress = useWatch({ name: 'trip.pickUpAddress' });
  const flight = useWatch({ name: '.flight' });

  const isAirport =
    pickUpAddress?.types?.includes('Airport') || flight?.flightId;

  const isTrainStation =
    pickUpAddress?.types?.includes('TrainStation') ||
    pickUpAddress?.types?.includes('TransitStation');

  const map = useMapStore((state) => ({
    setPickup: state.setPickUp,
    setDropOff: state.setDropOff,
    pickUp: state.pickUp,
    dropOff: state.dropOff,
    addStop: state.addStop,
    removeStop: state.removeStop,
  }));

  const [fetchPlaceDetails] = useFetchPlaceDetails();

  const handleReverseTrip = () => {
    const { trip } = getValues();
    const { pickUpAddress, dropOffAddress } = trip;

    map.setPickup(dropOffAddress);
    map.setDropOff(pickUpAddress);

    setValue('trip.pickUpAddress', dropOffAddress);
    setValue('trip.dropOffAddress', pickUpAddress);
    setValue('trip.stops', [], { shouldValidate: true });
    setValue('flight', null);
    onChange?.(getValues('trip'));
  };

  const handleRemoveStop =
    (fieldItem: Record<'id', string>, index: number) => () => {
      remove(index);

      if (fieldItem) {
        map.removeStop(index);
      }

      onChange?.(getValues('trip'));
    };

  const getLatLongFromPlace = async (place: PlaceAutocompleteValue) => {
    if (place.lat && place.long) {
      return {
        lat: place.lat,
        long: place.long,
      };
    }

    const placeDetail = await fetchPlaceDetails({
      variables: place.placeId
        ? {
            placeId: place.placeId,
          }
        : undefined,
    });

    const { long, lat } = placeDetail.data?.place.details?.location ?? {};

    return {
      lat: lat ?? 0,
      long: long ?? 0,
    };
  };

  const handlePlaceChange =
    (
      fieldName:
        | 'trip.pickUpAddress'
        | 'trip.dropOffAddress'
        | `trip.stops.${number}`
    ) =>
    async (place: PlaceAutocompleteValue | undefined | null) => {
      if (!place) {
        setValue(fieldName, null, {
          shouldValidate: true,
          shouldDirty: true,
        });

        if (fieldName === 'trip.pickUpAddress') {
          map.setPickup(null);
          setValue('flight', null);
        }

        if (fieldName === 'trip.dropOffAddress') {
          map.setDropOff(null);
        }

        if (fieldName.startsWith('trip.stops')) {
          const [, i] = fieldName.split('.');
          map.removeStop(+i);
        }

        onChange?.(getValues('trip'));
      } else {
        const { long, lat } = await getLatLongFromPlace(place);
        if (!long || !lat) return;

        const address: Address = {
          name: place.name,
          types: place.types,
          lat,
          long,
        };

        setValue(fieldName, address);

        if (fieldName === 'trip.dropOffAddress') {
          map.setDropOff(address);
        }

        if (fieldName === 'trip.pickUpAddress') {
          map.setPickup(address);
          setValue('flight', null);
        }

        if (fieldName.startsWith('trip.stops')) {
          const [, i] = fieldName.split('.');
          map.addStop(address, +i);
        }

        trigger(fieldName);
        onChange?.(getValues('trip'));
      }

      try {
        tripSchema(formatMessage).parse(getValues('trip'));
        clearErrors('trip.pickUpAddress');
      } catch (error) {
        if (error instanceof ZodError && error.issues[0]?.path[0] === 'trip') {
          setError('trip.pickUpAddress', {
            message: error.issues[0]?.message,
            type: 'manual',
          });
        }
      }
    };

  return (
    <>
      <div className="flex gap-2 items-center">
        <div className="flex grow flex-col gap-4">
          <Stop first>
            <Controller
              name="trip.pickUpAddress"
              control={control}
              render={({ field, fieldState }) => (
                <Tooltip.Root>
                  <Field.Root
                    name={field.name}
                    error={
                      fieldState.error?.message ??
                      formState.errors?.trip?.pickUpAddress?.message
                    }
                  >
                    <PlacePicker.Root
                      aria-label={formatMessage({
                        description: 'Pickup address label',
                        defaultMessage: 'Adresse de prise en charge',
                      })}
                      onSelectionChange={handlePlaceChange(field.name)}
                      selection={field.value}
                    >
                      <Tooltip.Trigger asChild>
                        <div className="flex gap-2 items-center w-full">
                          <Field.Control>
                            <PlacePicker.Search
                              className="w-full flex"
                              aria-label={formatMessage({
                                description: 'Pick up address placeholder',
                                defaultMessage: 'Votre lieu de prise en charge',
                              })}
                              onBlur={field.onBlur}
                              onFocus={(e) => e.preventDefault()}
                              placeholder={formatMessage({
                                description: 'Pick up address placeholder',
                                defaultMessage: 'Votre lieu de prise en charge',
                              })}
                            />
                          </Field.Control>
                        </div>
                      </Tooltip.Trigger>
                      <PlacePicker.Options
                        additionalFavoritesAddresses={
                          additionalFavoritesAddresses
                        }
                      />
                    </PlacePicker.Root>
                  </Field.Root>
                  {field.value?.name && (
                    <Tooltip.Portal>
                      <Tooltip.Content
                        side="right"
                        align="center"
                        className="hidden xl:block"
                      >
                        {field.value.name}
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  )}
                </Tooltip.Root>
              )}
            />
          </Stop>
          {isAirport && (
            <Stop noBullet>
              <FlightControl className="mb-4 w-full" />
            </Stop>
          )}
          {isTrainStation && (
            <Stop noBullet>
              <Controller
                name="trainReference"
                control={control}
                render={({ field }) => (
                  <TrainInput
                    {...field}
                    onChange={field.onChange}
                    className="w-full mb-4"
                    name={field.name}
                    label={formatMessage({
                      description: 'Train number',
                      defaultMessage: 'N° Train',
                    })}
                  />
                )}
              />
            </Stop>
          )}
          {fields.map((fieldItem, index) => (
            <Stop
              key={fieldItem.id}
              onRemove={handleRemoveStop(fieldItem, index)}
            >
              <Controller
                name={`trip.stops.${index}`}
                control={control}
                render={({ field, fieldState }) => (
                  <Tooltip.Root>
                    <Field.Root
                      name={field.name}
                      error={
                        fieldState.error?.message ??
                        formState.errors?.trip?.pickUpAddress?.message
                      }
                    >
                      <PlacePicker.Root
                        aria-label={formatMessage({
                          description: 'Stop address label',
                          defaultMessage: "Adresse de l'arrêt intermédiaire",
                        })}
                        onSelectionChange={handlePlaceChange(field.name)}
                        selection={field.value}
                      >
                        <Tooltip.Trigger asChild>
                          <div className="flex gap-2 items-center w-full">
                            <Field.Control>
                              <PlacePicker.Search
                                className="w-full flex"
                                onBlur={field.onBlur}
                                aria-label={formatMessage({
                                  description: 'Stop address label',
                                  defaultMessage:
                                    "Addresse de l'arrêt intermédiaire",
                                })}
                                autoFocus
                                onFocus={(e) => e.preventDefault()}
                                placeholder={formatMessage(
                                  {
                                    description: 'Stop address placeholder',
                                    defaultMessage:
                                      'Stop intermédiaire n°{count}',
                                  },
                                  {
                                    count: index + 1,
                                  }
                                )}
                              />
                            </Field.Control>
                          </div>
                        </Tooltip.Trigger>
                        <PlacePicker.Options
                          additionalFavoritesAddresses={
                            additionalFavoritesAddresses
                          }
                        />
                      </PlacePicker.Root>
                    </Field.Root>
                    {field.value?.name && (
                      <Tooltip.Portal>
                        <Tooltip.Content
                          side="right"
                          align="center"
                          className="hidden xl:block"
                        >
                          {field.value.name}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    )}
                  </Tooltip.Root>
                )}
              />
            </Stop>
          ))}
          <Stop last>
            <Controller
              name="trip.dropOffAddress"
              control={control}
              render={({ field, fieldState }) => (
                <Tooltip.Root>
                  <div className="grow">
                    <Field.Root
                      name={field.name}
                      error={
                        fieldState.error?.message ??
                        formState.errors?.trip?.pickUpAddress?.message
                      }
                    >
                      <PlacePicker.Root
                        aria-label={formatMessage({
                          description: 'Drop off address label',
                          defaultMessage: 'Votre lieu de destination',
                        })}
                        onSelectionChange={handlePlaceChange(field.name)}
                        selection={field.value}
                      >
                        <Tooltip.Trigger asChild>
                          <div className="flex gap-2 items-center w-full">
                            <Field.Control>
                              <PlacePicker.Search
                                className="w-full flex"
                                aria-label={formatMessage({
                                  description: 'Drop off address label',
                                  defaultMessage: 'Votre lieu de destination',
                                })}
                                onBlur={field.onBlur}
                                onFocus={(e) => e.preventDefault()}
                                placeholder={formatMessage({
                                  description: 'Drop off placeholder',
                                  defaultMessage: 'Votre destination',
                                })}
                              />
                            </Field.Control>
                          </div>
                        </Tooltip.Trigger>
                        <PlacePicker.Options
                          additionalFavoritesAddresses={
                            additionalFavoritesAddresses
                          }
                        />
                      </PlacePicker.Root>
                      <Field.ErrorMessage />
                    </Field.Root>
                  </div>
                  {field.value?.name && (
                    <Tooltip.Portal>
                      <Tooltip.Content
                        side="right"
                        align="center"
                        className="hidden xl:block"
                      >
                        {field.value.name}
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  )}
                </Tooltip.Root>
              )}
            />
          </Stop>
        </div>
        <div>
          {fields.length === 0 && (
            <IconButton
              type="button"
              onClick={handleReverseTrip}
              size="md"
              className="fill-primary bg-white rotate-90"
              aria-label={formatMessage({
                description: 'reverse the route button label',
                defaultMessage: 'Inverser le trajet',
              })}
            >
              <ReverseIcon />
            </IconButton>
          )}
        </div>
      </div>
      {fields.length < MAX_STOPS ? (
        <Button
          className="flex gap-2 w-min whitespace-nowrap ml-10 items-center"
          variant="secondary"
          type="button"
          onClick={() => {
            append(null);
          }}
        >
          <PlusIcon className="size-5 stroke-primary" strokeWidth={2} />
          <FormattedMessage
            description="Add stop button label"
            defaultMessage="Ajouter un arrêt"
          />
        </Button>
      ) : null}
    </>
  );
};
