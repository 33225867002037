import * as React from 'react';

import { createContext } from '@hermes/utils/context';
import { RideRequestCreateFeedbackDialog } from '../components/RideRequestCreateFeedbackDialog';
import {
  UIState,
  UIStateActions,
  bookingUIStateReducer,
  isRideError,
  resetAction,
} from './bookingUIStateReducer';

interface BookingUIState extends UIState {
  dispatch: (action: UIStateActions) => void;
}

const [useBookingUIState, Provider] = createContext<BookingUIState>();

const BookingUIStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [UIState, dispatch] = React.useReducer(
    bookingUIStateReducer,
    {
      feedback: null,
      loading: true,
    },
    () => ({
      feedback: null,
      loading: true,
    })
  );

  const value = React.useMemo(
    () => ({
      ...UIState,
      dispatch,
    }),
    [UIState]
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      dispatch(resetAction());
    }
  };

  return (
    <Provider value={value}>
      {children}
      <RideRequestCreateFeedbackDialog
        open={isRideError(UIState)}
        feedback={UIState.feedback}
        onOpenChange={handleOpenChange}
      />
    </Provider>
  );
};

export { useBookingUIState, BookingUIStateProvider };
