import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { mergeRefs } from 'react-merge-refs';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Callout,
  Combobox,
  ComboboxInputProps,
  InputProps,
  Spinner,
  inputClasses,
  inputSizeClasses,
} from '@hermes/ui';

import { useFetchFlight } from '../api';
import { FlightDetails } from './FlightDetails';

export interface FlightType {
  arrivalAirport: string;
  arrivalCity: string;
  departAirport: string;
  departTime: string;
  arrivalTime: string;
  departCity: string;
  flightId: number;
  flightCode: string;
  flightNumber: string;
  arrivalFsCode: string;
  arrivalLat: number;
  arrivalLong: number;
  arrivalTerminal?: string | null;
}

const Input = React.forwardRef(function InnerInput(
  {
    size = 'md',
    className,
    name,
    label,
    value,
    loading,
    ...props
  }: Omit<InputProps, 'value'> & {
    value: string;
    loading: boolean;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { formatMessage } = useIntl();
  const innerRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    innerRef.current?.focus();
  };

  return (
    <div
      className={twMerge(
        inputClasses,
        inputSizeClasses[size],
        'group flex items-center focus-within:border-primary hover:focus-within:border-primary hover:border-gray-300 aria-invalid:bg-red-100/20 aria-invalid:border-red-500 cursor-text p-0',
        className
      )}
      onClick={handleClick}
    >
      {label && (
        <label
          className="text-primary bg-gray-100 py-2 px-3 rounded-l-md border-r border-gray-200 whitespace-nowrap"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        ref={mergeRefs([ref, innerRef])}
        className="focus-visible:outline-none px-2 w-full"
        name={name}
        autoComplete="off"
        value={value}
        {...props}
        type="search"
      />
      <Spinner
        className={`mx-2 ${loading ? 'visible' : 'hidden'}`}
        size="sm"
        aria-hidden={!loading}
        text={formatMessage({
          description: 'Loading flight',
          defaultMessage: 'Recherche du vol en cours',
        })}
      />
    </div>
  );
});

interface FlightInputProps
  extends Omit<ComboboxInputProps<FlightType>, 'value' | 'onChange'> {
  value: FlightType | null;
  onChange: (value: FlightType | null) => void;
  flightArrivalDate?: string;
}

export const FlightInput = React.forwardRef(function FlightInputInner(
  {
    size = 'md',
    className,
    name,
    label,
    onChange,
    value,
    flightArrivalDate,
    ...props
  }: FlightInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { formatMessage } = useIntl();
  const [flight, setFlight] = React.useState<string>('');

  const { data, loading } = useFetchFlight({
    skip: flight.length < 3,
    variables: {
      input: {
        flightRef: flight,
        flightArrivalDate,
      },
    },
  });

  React.useEffect(() => {
    if (value) {
      setFlight(`${value.flightCode}${value.flightNumber}`);
    } else {
      setFlight('');
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFlight(e.target.value);
  };

  const handleBlur = () => {
    setFlight('');
  };

  return (
    <Combobox.Root<FlightType | null>
      onSelectionChange={onChange}
      selection={value ?? null}
      nullable
    >
      <Combobox.Input<FlightType>
        {...props}
        className={className}
        ref={ref}
        as={Input}
        label={label}
        size={size}
        onChange={handleChange}
        onBlur={handleBlur}
        loading={loading}
        type="search"
        autoComplete="off"
        displayValue={(option) => {
          if (!option) {
            return '';
          }

          return formatMessage(
            {
              description: 'Flight input display value',
              defaultMessage:
                '{flightCode}{flightNumber} - arrivée prévue à {arrivalTime, time, ::HH mm} ({arrivalAirport})',
            },
            {
              flightCode: option.flightCode.toUpperCase(),
              flightNumber: option.flightNumber,
              arrivalTime: new Date(option.arrivalTime),
              arrivalAirport: option.arrivalFsCode,
            }
          );
        }}
      />
      <Combobox.Popover
        title={
          <FormattedMessage
            description="Flight input popover title"
            defaultMessage="Sélectionnez votre vol"
          />
        }
      >
        <Combobox.Options>
          {flight.length >= 3 && !data && !loading && (
            <div className="p-4 text-primary text-sm">
              <Callout variant="info">
                <strong className="text-base">
                  <FormattedMessage
                    description="Flight not found"
                    defaultMessage="Aucun vol trouvé pour cette référence au {date, date, ::d MMM}."
                    values={{
                      date: flightArrivalDate
                        ? new Date(flightArrivalDate)
                        : null,
                    }}
                  />
                </strong>
                <p>
                  <FormattedMessage
                    description="Flight not found hint"
                    defaultMessage="Veuillez-vous assurer que la référence est correcte et que la date de votre vol correspond bien à la date de votre course."
                  />
                </p>
              </Callout>
              <Callout variant="warning" className="mt-4">
                <p>
                  <FormattedMessage
                    description="Flight not found hint easy-jet"
                    defaultMessage="Attention, pour les vols Easyjet merci d'utiliser la référence EZY au lieu de EJU."
                    values={{
                      date: flightArrivalDate
                        ? new Date(flightArrivalDate)
                        : null,
                    }}
                  />
                </p>
              </Callout>
            </div>
          )}
          {flight.length >= 3 &&
            data?.flights.map((flight) => (
              <Combobox.Option
                value={flight}
                key={flight.flightId}
                className="text-sm"
              >
                <FlightDetails flight={flight} />
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </Combobox.Popover>
    </Combobox.Root>
  );
});
