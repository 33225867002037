import { FlightType } from '@customer-booking/features/flights';
import { CalendarDate, Time } from '@internationalized/date';
import { PlaceAutocompleteValue } from './PlacePicker';

export const RideRequestType = {
  asap: 'Asap',
  placement: 'Placement',
  planned: 'Planned',
} as const;

export type RideRequestType =
  (typeof RideRequestType)[keyof typeof RideRequestType];

export interface RideFormValues {
  options?: { id: number }[];
  trip: {
    pickUpAddress: PlaceAutocompleteValue | null;
    dropOffAddress: PlaceAutocompleteValue | null;
    stops: PlaceAutocompleteValue[] | null;
  };
  rideType: RideRequestType;
  vehicleType: string;
  time?: Time;
  date?: CalendarDate;
  flight?: FlightType | null;
  trainReference?: string;
}
