import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  FetchPlaceDetailsQuery,
  FetchPlaceDetailsQueryVariables,
} from '@customer-booking/__generated__/graphql';

export const FETCH_PLACE_DETAILS = gql(/* GraphQL */ `
  query FetchPlaceDetails($placeId: String!) {
    place {
      details(id: $placeId) {
        id
        location {
          lat
          long
        }
      }
    }
  }
`);

export const useFetchPlaceDetails = (
  variables?: FetchPlaceDetailsQueryVariables,
  options?: Omit<
    QueryHookOptions<FetchPlaceDetailsQuery, FetchPlaceDetailsQueryVariables>,
    'variables'
  >
) => {
  return useLazyQuery(FETCH_PLACE_DETAILS, {
    ...options,
    variables,
  });
};
