import { QueryHookOptions, useQuery } from '@apollo/client';

import { gql } from '@customer-booking/__generated__';
import {
  FetchFlightQuery,
  FetchFlightQueryVariables,
} from '@customer-booking/__generated__/graphql';

const FETCH_FLIGHT = gql(/* GraphQL */ `
  query FetchFlight($input: FlightsInput!) {
    flights(input: $input) {
      arrivalAirport
      flightRef
      arrivalCity
      departAirport
      departTime
      arrivalTime
      departCity
      flightId
      flightCode
      flightNumber
      arrivalFsCode
      arrivalLat
      arrivalLong
      arrivalTerminal
    }
  }
`);

export const useFetchFlight = (
  options?: QueryHookOptions<FetchFlightQuery, FetchFlightQueryVariables>
) => {
  return useQuery(FETCH_FLIGHT, options);
};
