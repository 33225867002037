import { RideRequestType } from '@customer-booking/__generated__/graphql';
import { useFetchVehiclesTypes } from './fetchVehicleTypes';

interface LatLong {
  lat: number;
  long: number;
}

export const useFetchRideAvailableVehicles = ({
  pickUp,
  dropOff,
  rideType,
  includeOptions = false,
}: {
  pickUp?: LatLong;
  dropOff?: LatLong;
  rideType: RideRequestType;
  includeOptions?: boolean;
}) => {
  return useFetchVehiclesTypes(
    pickUp && dropOff
      ? {
          pickUp,
          dropOff,
          rideRequestType: rideType,
          includeOptions,
        }
      : undefined,
    { skip: !pickUp || !dropOff }
  );
};
