import * as React from 'react';
import { Button, ButtonProps } from '@hermes/ui';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';

import { useCurrentRideId } from '../../hooks';

export const CancelButton = ({
  className,
  size,
}: Omit<ButtonProps, 'children'>) => {
  const router = useRouter();
  const rideId = useCurrentRideId();

  if (!rideId) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      className={className}
      size={size}
      onClick={() =>
        router.push({
          pathname: '/rides/[id]',
          query: { id: rideId },
        })
      }
    >
      <FormattedMessage
        description="Cancel ride button"
        defaultMessage="Annuler"
      />
    </Button>
  );
};
