import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { mergeRefs } from 'react-merge-refs';
import { InputProps, inputClasses, inputSizeClasses } from '@hermes/ui';

const Input = React.forwardRef(function InnerInput(
  { size = 'md', className, name, label, ...props }: Omit<InputProps, 'value'>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const innerRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    innerRef.current?.focus();
  };

  return (
    <div
      className={twMerge(
        inputClasses,
        inputSizeClasses[size],
        'group flex items-center focus-within:border-primary hover:focus-within:border-primary hover:border-gray-300 aria-invalid:bg-red-100/20 aria-invalid:border-red-500 cursor-text p-0',
        className
      )}
      onClick={handleClick}
    >
      {label && (
        <label
          className="text-primary bg-gray-100 py-2 px-3 rounded-l-md border-r border-gray-200 whitespace-nowrap"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        ref={mergeRefs([ref, innerRef])}
        className="focus-visible:outline-none px-2 w-full"
        name={name}
        id={name}
        autoComplete="off"
        {...props}
      />
    </div>
  );
});

interface TrainInputProps {
  size?: 'sm' | 'md' | 'lg' | undefined;
  className?: string;
  name: string;
  label: string;
  onChange: (value: string) => void;
}

export const TrainInput: React.FC<TrainInputProps> = ({
  size = 'md',
  className,
  name,
  label,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Input
      size={size}
      className={className}
      name={name}
      label={label}
      onChange={handleChange}
    />
  );
};
