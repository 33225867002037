/* eslint-disable react/display-name */
import * as React from 'react';
import * as R from 'remeda';
import { FormattedMessage, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';
import { isApolloError } from '@apollo/client';

import {
  PlaceAutocompleteType,
  PlaceAutocompleteTypeResponse,
} from '@customer-booking/__generated__/graphql';
import {
  AirplaneIcon,
  ClockIcon,
  LocationCrosshairIcon,
  PositionIcon,
  TrainIcon,
} from '@hermes/icons/simple';
import {
  Combobox,
  ComboboxRootProps,
  ComboboxInputProps,
  ToggleButtonGroup,
  Spinner,
} from '@hermes/ui';
import { createContext } from '@hermes/utils/context';
import {
  useSearchPlaces,
  useCurrentPlace,
} from '@customer-booking/features/places';
import { isGeoloactionError } from '@hermes/utils/hooks';
import {
  useCustomerFavoriteAddresses,
  useHistoricAddresses,
} from '@customer-booking/features/customer/api';
import { Separator } from '@radix-ui/react-select';
import { useAuth } from '@customer-booking/features/auth';

interface PlacePickerContext {
  search: string;
  selection: PlaceAutocompleteValue | null;
  setSearch: (search: string) => void;
  onSelectionChange?: (value: PlaceAutocompleteValue | null) => void;
}

const [usePlacePicker, PlacePickerProvider] =
  createContext<PlacePickerContext>();

export interface PlaceAutocompleteValue {
  name: string;
  placeId?: string;
  types?: PlaceAutocompleteTypeResponse[];
  lat: number;
  long: number;
}
export interface Address {
  id?: number;
  name: string;
  lat: number;
  long: number;
  address: string;
}

type RootProps = ComboboxRootProps<PlaceAutocompleteValue | null>;

const PlacePickerRoot = ({ onSelectionChange, ...props }: RootProps) => {
  const [search, setSearch] = React.useState('');

  return (
    <PlacePickerProvider
      value={{
        selection: props.selection ?? null,
        search,
        setSearch,
        onSelectionChange,
      }}
    >
      <Combobox.Root<PlaceAutocompleteValue | null>
        {...props}
        nullable
        onSelectionChange={onSelectionChange}
        onOpenChange={(open) => {
          if (!open) {
            setSearch('');
          }
        }}
      />
    </PlacePickerProvider>
  );
};

type PlacePickerInputProps = ComboboxInputProps<PlaceAutocompleteValue | null>;

const PlacePickerSearch = React.forwardRef(function PlacePickerSearchInner(
  { value, ...props }: PlacePickerInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { setSearch } = usePlacePicker();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Combobox.Input<PlaceAutocompleteValue | null>
      {...props}
      ref={ref}
      onChange={handleChange}
      displayValue={(place) => (place ? place.name : '')}
      type="search"
      autoComplete="off"
    />
  );
});

const PlacePickerOptions = ({
  additionalFavoritesAddresses,
}: {
  additionalFavoritesAddresses?: Address[];
}) => {
  const { search, onSelectionChange } = usePlacePicker();
  const [debouncedSearch, setDebouncedSearch] = React.useState(search);

  const { status } = useAuth();

  const { data: customerFavoriteAddresses, loading: favoriteAddressesLoading } =
    useCustomerFavoriteAddresses({
      skip: status !== 'authenticated',
    });

  const { data: historicAddresses, loading: historicAddressesLoading } =
    useHistoricAddresses(3, {
      skip: status !== 'authenticated',
    });

  const favoriteAddresses = R.uniqBy(
    additionalFavoritesAddresses?.concat(customerFavoriteAddresses) ??
      customerFavoriteAddresses,
    (address) => address.id
  );

  const isDataLoading = favoriteAddressesLoading || historicAddressesLoading;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(search);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const [placeType, setPlaceType] =
    React.useState<PlaceAutocompleteType | null>(null);

  const typesParam =
    placeType === 'Airport'
      ? 'Airport'
      : placeType === 'TrainStation'
      ? ['TrainStation' as const, 'TransitStation' as const]
      : undefined;

  const { data, previousData, loading, variables } = useSearchPlaces(
    {
      input: debouncedSearch,
      types: typesParam,
    },
    {
      skip: !debouncedSearch,
    }
  );

  const handleTypeChange = (value: string | undefined) => {
    if (value && ['Airport', 'TrainStation'].includes(value)) {
      setPlaceType(value as PlaceAutocompleteType);
    } else {
      setPlaceType(null);
    }
  };

  const getIcon = (placeType: PlaceAutocompleteTypeResponse[]) => {
    if (placeType.includes('Airport')) {
      return <AirplaneIcon className="fill-primary stroke-primary size-5" />;
    }

    if (
      placeType.includes('TrainStation') ||
      placeType.includes('TransitStation')
    ) {
      return <TrainIcon className="stroke-primary fill-transparent size-5" />;
    }

    return <PositionIcon className="fill-primary size-5" />;
  };

  const noResultsMessage =
    placeType === 'Airport' ? (
      <FormattedMessage
        description="No airports found"
        defaultMessage="Pas d'aéroports trouvés"
      />
    ) : placeType === 'TrainStation' ? (
      <FormattedMessage
        description="No train stations found"
        defaultMessage="Pas de gares trouvées"
      />
    ) : (
      <FormattedMessage
        description="No places found"
        defaultMessage="Aucun lieu trouvé"
      />
    );

  const displayedData =
    data?.place.autocomplete ?? previousData?.place.autocomplete ?? [];

  const inputValue = variables?.input ?? '';

  const displayNoResults =
    displayedData.length === 0 && inputValue.length > 0 && search !== '';

  const displaySearchResults = !(search === '' || (loading && !displayedData));

  const displayCurrentPosition = search === '' && !isDataLoading;
  const displayFilters = displaySearchResults;
  const displayCustomerOptions = search === '' && !isDataLoading;

  return (
    <Combobox.Popover
      title={
        <FormattedMessage
          defaultMessage="Rechercher un lieu"
          description="Search for a place modal title"
        />
      }
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      {displayFilters && (
        <div className="flex gap-2 p-4">
          <ToggleButtonGroup.Root
            variant="secondary"
            onValueChange={handleTypeChange}
            value={(placeType ?? '') as string}
            indeterminate
          >
            <ToggleButtonGroup.Item value="TrainStation" className="py-1">
              <FormattedMessage
                description="Train station filter"
                defaultMessage="Gares"
              />
            </ToggleButtonGroup.Item>
            <ToggleButtonGroup.Item value="Airport" className="py-1">
              <FormattedMessage
                description="Airport filter"
                defaultMessage="Aéroports"
              />
            </ToggleButtonGroup.Item>
          </ToggleButtonGroup.Root>
        </div>
      )}
      {displayCurrentPosition && (
        <CurrentUserPosition onClick={onSelectionChange} />
      )}
      {displayNoResults && (
        <div className="p-4 text-center text-primary">
          <strong>{noResultsMessage}</strong>
        </div>
      )}
      {isDataLoading && (
        <>
          <Combobox.OptionSkeleton count={1} />
          <Separator className="h-[1px] bg-primary/30" />
          <Combobox.OptionSkeleton count={2} />
          <Separator className="h-[1px] bg-primary/30" />
          <Combobox.OptionSkeleton count={2} />
        </>
      )}
      {displayCustomerOptions && (
        <>
          <Combobox.Options>
            <PlacePickerFavorites addresses={favoriteAddresses} />
            <CustomerHistoricAdresses addresses={historicAddresses} />
          </Combobox.Options>
        </>
      )}
      {displaySearchResults && (
        <>
          <Combobox.Options>
            {displayedData.map((place) => {
              const icon = getIcon(place.types);

              return (
                <Combobox.Option
                  key={place.id}
                  value={{
                    name: [place.primary, place.secondary].join(' – '),
                    placeId: place.id,
                    types: place.types,
                  }}
                  className={twJoin(
                    'first-of-type:rounded-none',
                    false && 'px-8 -mx-6 py-4'
                  )}
                >
                  {icon}
                  <div>
                    <div>
                      <strong>{place.primary}</strong>
                    </div>
                    {place.secondary}
                  </div>
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        </>
      )}
    </Combobox.Popover>
  );
};

export const CustomerHistoricAdresses = ({
  addresses = [],
}: {
  addresses?: Address[];
}) => {
  if (addresses.length === 0) return null;

  return (
    <>
      <Separator className="h-[1px] bg-primary/30" />
      <div className="text-primary font-bold p-4 flex items-center gap-2 w-full">
        <p>
          <strong className="block">
            <FormattedMessage
              description="Historic addresses"
              defaultMessage="Récents"
            />
          </strong>
        </p>
      </div>
      {addresses.map(({ name, lat, long, address }) => (
        <Combobox.Option
          key={name}
          value={{
            name,
            lat,
            long,
            types: [],
          }}
          className={twJoin(
            'first-of-type:rounded-none',
            false && 'px-8 -mx-6 py-4'
          )}
        >
          <ClockIcon className="size-5 fill-primary" />
          <p className="text-primary">{address}</p>
        </Combobox.Option>
      ))}
    </>
  );
};

export const CurrentUserPosition = ({
  onClick,
}: {
  onClick?: (value: PlaceAutocompleteValue | null) => void;
}) => {
  const { formatMessage } = useIntl();
  const { error, getPosition, loading } = useCurrentPlace();

  if (error) {
    return (
      <div className="flex items-center gap-1 p-4">
        <LocationCrosshairIcon className="size-5 fill-primary" />
        <p className="p-4 text-primary">
          <strong className="block">
            <FormattedMessage
              description="Your current location is unavailable"
              defaultMessage="Votre position actuelle est indisponible"
            />
          </strong>
          {isGeoloactionError(error) ? (
            <FormattedMessage
              description="Allow your browser to share your location"
              defaultMessage="Veuillez autoriser votre navigateur à partager votre position"
            />
          ) : isGeoloactionError(error) ? (
            <FormattedMessage
              description="Geolocation not supported by your browser"
              defaultMessage="La géolocalisation n'est pas supportée par votre navigateur"
            />
          ) : isApolloError(error) ? (
            <FormattedMessage
              description="Unable to retrieve your current location"
              defaultMessage="Impossible de récupérer votre position actuelle"
            />
          ) : undefined}
        </p>
      </div>
    );
  }

  const handleClick = () => {
    getPosition({
      onSuccess: (place) => {
        if (place && onClick) {
          onClick({
            name: place.name ?? '',
            placeId: place.id,
            types: [],
            lat: 0,
            long: 0,
          });
        }
      },
    });
  };

  return (
    <button
      className={twJoin(
        'first-of-type:rounded-none text-primary font-bold p-4 flex items-center gap-2 w-full',
        false && 'px-8 -mx-6 py-4'
      )}
      onClick={handleClick}
      aria-label={formatMessage({
        description: 'Your current position',
        defaultMessage: 'Votre position actuelle',
      })}
    >
      {loading && (
        <>
          <Spinner size="sm" />
          <FormattedMessage
            description="Searching for your current position"
            defaultMessage="Recherche de votre position en cours..."
          />
        </>
      )}
      {!loading && (
        <>
          <LocationCrosshairIcon className="size-5 fill-primary" />
          <FormattedMessage
            description="Your current position"
            defaultMessage="Votre position actuelle"
          />
        </>
      )}
    </button>
  );
};

export const PlacePickerFavorites = ({
  addresses = [],
}: {
  addresses?: Address[];
}) => {
  if (addresses.length === 0) return null;

  return (
    <>
      <Separator className="h-[1px] bg-primary/30" />
      <div className="text-primary font-bold p-4 flex items-center gap-2 w-full">
        <p>
          <strong className="block">
            <FormattedMessage
              description="favorite address"
              defaultMessage="Favoris"
            />
          </strong>
        </p>
      </div>
      {addresses.map(({ id, name, lat, long, address }) => (
        <Combobox.Option
          key={id}
          value={{
            name,
            lat,
            long,
            types: [],
          }}
          className={twJoin(
            'first-of-type:rounded-none',
            false && 'px-8 -mx-6 py-4'
          )}
        >
          <PositionIcon className="size-5 fill-primary" />
          <p className="text-primary">{address}</p>
        </Combobox.Option>
      ))}
    </>
  );
};

export const PlacePicker = {
  Root: PlacePickerRoot,
  Search: PlacePickerSearch,
  Options: PlacePickerOptions,
};
