import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Field,
  Fieldset as FieldsetPrimitive,
  FieldsetProps,
} from '@hermes/ui';
import { ClientError } from '@hermes/icons/colored';
import {
  VehicleTypesList,
  VehicleTypesSkeleton,
  useFetchRideAvailableVehicles,
} from '@customer-booking/features/vehicles';

import { RideFormValues } from '../RideForm.types';
import { getLatLong } from '../helpers';
import {
  VehicleFieldContextProvider,
  useVehicleField,
} from './VehicleFieldContext';

function VehicleTypesListFieldRoot({
  children,
}: {
  children: React.ReactNode;
}) {
  const { setValue, getValues } = useFormContext<RideFormValues>();

  const pickUp = useWatch({
    name: 'trip.pickUpAddress',
  }) as RideFormValues['trip']['pickUpAddress'] | undefined;
  const dropOff = useWatch({
    name: 'trip.dropOffAddress',
  }) as RideFormValues['trip']['dropOffAddress'] | undefined;
  const rideType = useWatch({ name: 'rideType' }) as RideFormValues['rideType'];

  const {
    data: vehiclesTypes,
    error: vehiclesTypesError,
    loading: vehiclesTypesLoading,
  } = useFetchRideAvailableVehicles({
    pickUp: pickUp ? getLatLong(pickUp) : undefined,
    dropOff: dropOff ? getLatLong(dropOff) : undefined,
    rideType: rideType,
    includeOptions: true,
  });

  const resetSelectedVehicleType = React.useCallback(() => {
    const vehicleType = vehiclesTypes?.saasCompany.vehicleTypes[0].id;
    if (vehicleType) {
      setValue('vehicleType', String(vehicleType), {
        shouldValidate: true,
      });
    }
  }, [setValue, vehiclesTypes]);

  React.useEffect(() => {
    const vehicleType = getValues('vehicleType');

    if (!vehiclesTypes) {
      return;
    }

    if (!vehicleType) {
      resetSelectedVehicleType();
      return;
    }

    const vehicleTypeExists = vehiclesTypes.saasCompany.vehicleTypes.some(
      (type) => type.id === Number(vehicleType)
    );

    if (vehicleType && !vehicleTypeExists) {
      resetSelectedVehicleType();
    }
  }, [vehiclesTypes, resetSelectedVehicleType, getValues]);

  const error = vehiclesTypesError;

  const ctx = React.useMemo(
    () => ({
      loading: vehiclesTypesLoading,
      error,
      vehiclesTypes,
    }),
    [vehiclesTypesLoading, error, vehiclesTypes]
  );

  return (
    <VehicleFieldContextProvider value={ctx}>
      {children}
    </VehicleFieldContextProvider>
  );
}

const Fieldset = (props: FieldsetProps) => {
  const { vehiclesTypes, loading, error } = useVehicleField();

  if (error) {
    /* @ts-expect-error wrong types */
    const errorCode = error.graphQLErrors[0].extensions.errorCodes[0];

    if (errorCode === 'NO_VEHICLE_AVAILABLE') {
      return (
        <FieldsetPrimitive {...props}>
          <div className="flex flex-col items-center justify-center space-y-2 min-h-[316px] max-w-[250px] m-auto">
            <div>
              <ClientError width={100} height={131} />
            </div>
            <p className="text-center">
              <FormattedMessage
                description="No vehicule available error"
                defaultMessage="Pour réserver en province, merci de contacter le 01 70 95 14 15"
              />
            </p>
          </div>
        </FieldsetPrimitive>
      );
    }
  }

  if (loading) {
    return (
      <FieldsetPrimitive {...props}>
        <VehicleTypesSkeleton />
      </FieldsetPrimitive>
    );
  }

  if (!vehiclesTypes) return null;

  if (vehiclesTypes.saasCompany.vehicleTypes.length === 0) {
    return (
      <FieldsetPrimitive {...props}>
        <p>
          <FormattedMessage
            description="No vehicle available"
            defaultMessage="Aucun véhicule disponible. Merci de contacter le 01 70 95 14 15."
          />
        </p>
      </FieldsetPrimitive>
    );
  }

  return <FieldsetPrimitive {...props} />;
};

const VehicleTypesListControl = ({
  className,
  onChange,
}: {
  className?: string;
  onChange?: (value: string) => void;
}) => {
  const { control, setValue, getValues } = useFormContext<RideFormValues>();
  const { vehiclesTypes } = useVehicleField();

  const options = vehiclesTypes?.saasCompany.vehicleTypes ?? [];

  return (
    <Controller
      name="vehicleType"
      control={control}
      render={({ field }) => (
        <Field.Root name={field.name}>
          <Field.Control>
            <VehicleTypesList
              className={className}
              onChange={(value) => {
                field.onChange(value);

                if (getValues('options')) {
                  setValue('options', []);
                }

                onChange?.(value);
              }}
              value={field.value}
              options={options}
            />
          </Field.Control>
        </Field.Root>
      )}
    />
  );
};

export const VehicleTypesListField = {
  Root: VehicleTypesListFieldRoot,
  Fieldset,
  List: VehicleTypesListControl,
};
