import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DatePicker, Field, SelectTime } from '@hermes/ui';
import { todayLocalTime, isTodayLocalTime, time } from '@hermes/utils/date';
import { CalendarDate, Time } from '@internationalized/date';

import { RideFormValues, RideRequestType } from './RideForm.types';
import { getFirstTimeSlotForRide } from '../../helpers';
import { RIDE_REQUEST_TIME_OFFSET } from '../../constants';

export function DateFields({
  onChange,
}: {
  onChange?: (values: { date?: CalendarDate; time?: Time }) => void;
}) {
  const { formatMessage } = useIntl();
  const { watch, control, setValue, trigger, resetField } =
    useFormContext<RideFormValues>();

  const rideType = watch('rideType');
  const date = watch('date');
  const timeField = watch('time');

  React.useEffect(() => {
    if (rideType === RideRequestType.planned && !date && !timeField) {
      setValue('date', todayLocalTime(), { shouldValidate: true });
      setValue('time', getFirstTimeSlotForRide(), {
        shouldValidate: true,
      });
    }

    if (rideType !== RideRequestType.planned && date && timeField) {
      setValue('date', undefined);
      setValue('time', undefined);
      trigger('date');
      trigger('time');
    }
  }, [rideType, setValue, trigger, resetField, date, timeField]);

  const isDateToday = isTodayLocalTime(date ?? todayLocalTime());

  const startTime = isDateToday
    ? time().add({ minutes: RIDE_REQUEST_TIME_OFFSET })
    : time().set({ minute: 0, hour: 0 });

  return (
    <div className="flex gap-2">
      <Controller
        name="date"
        control={control}
        render={({ field, fieldState }) => (
          <Field.Root name={field.name} error={fieldState.error?.message}>
            <Field.Control>
              <DatePicker<CalendarDate>
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  onChange?.({
                    date: value,
                    time: timeField,
                  });
                  setValue('flight', null);
                }}
                aria-label={formatMessage({
                  description: 'Date label',
                  defaultMessage: 'Date',
                })}
                minValue={todayLocalTime()}
                maxValue={todayLocalTime().add({ days: 45 })}
                defaultValue={todayLocalTime()}
              />
            </Field.Control>
            <Field.ErrorMessage className="absolute -bottom-6" />
          </Field.Root>
        )}
      />
      <Controller
        name="time"
        control={control}
        render={({ field }) => (
          <SelectTime.Root
            onValueChange={(value) => {
              field.onChange(value);
              onChange?.({
                date,
                time: value,
              });
            }}
            start={startTime}
            value={field.value}
          >
            <Field.Root name={field.name}>
              <Field.Control>
                <SelectTime.Trigger
                  aria-label={formatMessage({
                    description: 'Time label',
                    defaultMessage: 'Heure',
                  })}
                />
              </Field.Control>
              <SelectTime.Content />
            </Field.Root>
          </SelectTime.Root>
        )}
      />
    </div>
  );
}
