import { Button } from '@hermes/ui';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Spinner } from '@customer-booking/components/Spinner';
import { twMerge } from 'tailwind-merge';
import { useWatchMedia, useWatchScroll } from '@hermes/utils/hooks';
import { RideFormValues } from './RideForm.types';
import { CancelButton } from './CancelButton';

interface SubmitButtonProps {
  isLoading?: boolean;
  isEdit?: boolean;
  visibility?: 'always' | 'valid';
  className?: string;
}

export const SubmitButton = ({
  isLoading = false,
  isEdit = false,
  visibility = 'valid',
  className,
}: SubmitButtonProps) => {
  const { formState, watch } = useFormContext<RideFormValues>();

  const isLargeScreen = useWatchMedia('xl', false);

  const getScrollContainer = () => {
    try {
      const elementId = isLargeScreen ? '__leftScrollArea' : '__next';
      return window.document.getElementById(elementId);
    } catch {
      return null;
    }
  };

  const [scrollArea, setScrollArea] = React.useState<HTMLElement | null>(
    getScrollContainer
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    setScrollArea(getScrollContainer());
  });

  const { reachBottom } = useWatchScroll(scrollArea);

  const hasError = Object.values(formState.errors).length > 0;
  const vehiclesType = watch('vehicleType');

  if (!vehiclesType && visibility === 'valid') return null;
  const isDisabled = hasError || isLoading || !formState.isValid;

  return (
    <div
      className={twMerge(
        'sticky bottom-0 z-30 px-4 -mx-4 py-4 -mb-4 calc(100% + 4rem) transition-shadow duration-300 flex gap-2 bg-white',
        !reachBottom && 'shadow-[0px_-6px_14px_5px_#0000001A]',
        className
      )}
    >
      <CancelButton className="h-12 w-full" />
      <Button
        variant="accent"
        size="md"
        className="h-12 px-8 w-full"
        disabled={isDisabled}
      >
        {isLoading ? (
          <Spinner variant="accent" size="sm" inverted />
        ) : isEdit ? (
          <FormattedMessage
            description="Get a quote button"
            defaultMessage="Modifier"
          />
        ) : (
          <FormattedMessage
            description="Get a quote button"
            defaultMessage="Obtenir un devis"
          />
        )}
      </Button>
    </div>
  );
};
