import * as React from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { FetchCustomerFavoriteAddressesQuery } from '@customer-booking/__generated__/graphql';
import gql from 'graphql-tag';

const FETCH_CUSTOMER_FAVORITE_ADDRESSES = gql`
  query FetchCustomerFavoriteAddresses {
    customer {
      id @client
      favoritesAddresses {
        address
        id
        lat
        long
        name
      }
    }
  }
`;

export const useCustomerFavoriteAddresses = (
  options?: QueryHookOptions<FetchCustomerFavoriteAddressesQuery>
) => {
  const { data, ...query } = useQuery<FetchCustomerFavoriteAddressesQuery>(
    FETCH_CUSTOMER_FAVORITE_ADDRESSES,
    options
  );

  const favoriteAddresses = React.useMemo(
    () =>
      (data?.customer?.favoritesAddresses || []).map(
        ({ __typename, ...address }) => address
      ),
    [data?.customer?.favoritesAddresses]
  );

  return { data: favoriteAddresses, ...query };
};
