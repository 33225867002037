import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

import { FlightInput, FlightType } from '@customer-booking/features/flights';
import {
  Button,
  Dialog,
  DialogRootProps,
  Field,
  ToggleButtonGroup,
  roundTime,
} from '@hermes/ui';
import {
  createCalendarDate,
  createCalendarDateTime,
  createTime,
} from '@hermes/utils/date';
import { useMapStore } from '@customer-booking/features/map';

import { RideFormValues } from './RideForm.types';
import { useModal } from '@hermes/utils/hooks';

const addOffset = (date: Date, minutes: number) => {
  const nextDateTime = createCalendarDateTime(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    0
  ).add({
    minutes,
  });

  return nextDateTime;
};

export const FlightControl = ({ className }: { className?: string }) => {
  const { setValue, watch } = useFormContext<RideFormValues>();

  const modalProps = useModal();

  const map = useMapStore((state) => ({
    setPickup: state.setPickUp,
  }));

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFlightChange = (flight: FlightType | null) => {
    if (flight) {
      const terminal = flight.arrivalTerminal
        ? `– Terminal ${flight.arrivalTerminal}`
        : '';

      const address = {
        name: `${flight.arrivalAirport ?? ''}${terminal} - ${
          flight.arrivalCity ?? ''
        }`,
        lat: flight.arrivalLat,
        long: flight.arrivalLong,
        types: ['Airport' as const],
      };

      setValue('trip.pickUpAddress', address);

      modalProps.onOpenChange(true);

      map.setPickup(address);
    } else {
      setValue('flight', null, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleSubmit = (offset: string) => {
    modalProps.onOpenChange(false);

    const flight = watch('flight') as FlightType | null;
    if (flight) {
      const arrivalTime = new Date(flight.arrivalTime);
      const nextDateTime = addOffset(arrivalTime, parseInt(offset));

      const nextTime = roundTime(
        createTime(nextDateTime.hour, nextDateTime.minute)
      );

      const nextCalendarDate = createCalendarDate(
        nextDateTime.year,
        nextDateTime.month,
        nextDateTime.day
      );

      setValue('date', nextCalendarDate);
      setValue('time', nextTime.roundedValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const date = watch('date');

  return (
    <>
      <div className={className}>
        <Controller<RideFormValues>
          name="flight"
          render={({ field }) => (
            <Field.Root name="flight">
              <Field.Control>
                <FlightInput
                  ref={inputRef}
                  onChange={(flight) => {
                    field.onChange(flight);
                    handleFlightChange(flight);
                  }}
                  value={field.value as FlightType | null}
                  label={formatMessage({
                    description: 'Flight number label',
                    defaultMessage: 'N° vol',
                  })}
                  placeholder={formatMessage({
                    description: 'Flight number placeholder',
                    defaultMessage: 'AF 1234',
                  })}
                  className="w-full"
                  flightArrivalDate={date?.toString() ?? undefined}
                />
              </Field.Control>
            </Field.Root>
          )}
        />
        <FlightOffsetDialog {...modalProps} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

const FlightOffsetDialog = ({
  onSubmit,
  ...props
}: {
  onSubmit: (flightOffset: string) => void;
} & DialogRootProps) => {
  const OFFSET_OPTIONS = React.useMemo(() => {
    return [
      {
        value: '30',
        description: (
          <>
            <span>
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="+ 30 minutes"
              />
            </span>
            <span className="text-gray-500 text-sm">
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="Adapté aux vols européens avec bagages cabine."
              />
            </span>
          </>
        ),
      },
      {
        value: '45',
        description: (
          <>
            <span>
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="+ 45 minutes"
              />
            </span>
            <span className="text-gray-500 text-sm">
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="Adapté aux vols européens avec bagages en soute."
              />
            </span>
          </>
        ),
      },
      {
        value: '60',
        description: (
          <>
            <span>
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="+ 60 minutes"
              />
            </span>
            <span className="text-gray-500 text-sm">
              <FormattedMessage
                description="Flight timing later button"
                defaultMessage="Adapté aux vols internationaux avec bagages en soute."
              />
            </span>
          </>
        ),
      },
    ];
  }, []);

  const [offset, setOffset] = React.useState(OFFSET_OPTIONS[0].value);

  const handleValueChange = (value: string) => {
    setOffset(value);
  };

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay>
          <Dialog.Content>
            <Dialog.CloseButton />
            <Dialog.Title>
              <FormattedMessage
                description="Flight timing title"
                defaultMessage="Adaptez votre heure de prise en charge"
              />
            </Dialog.Title>
            <Dialog.Description>
              <>
                <FormattedMessage
                  description="Flight timing message"
                  defaultMessage="Modifiez l'heure de prise en charge de votre course pour vous laisser le temps de récupérer vos bagages."
                />
                <ToggleButtonGroup.Root
                  variant="radio"
                  onValueChange={handleValueChange}
                  value={offset}
                  className="mt-4 flex flex-col gap-2"
                >
                  {OFFSET_OPTIONS.map(({ value, description }) => (
                    <ToggleButtonGroup.Item
                      key={value}
                      value={value}
                      className="flex py-3"
                    >
                      <ToggleButtonGroup.ItemIndicator />
                      <div className="flex flex-col gap-0 px-4 text-left">
                        {description}
                      </div>
                    </ToggleButtonGroup.Item>
                  ))}
                </ToggleButtonGroup.Root>
              </>
            </Dialog.Description>
            <div className="flex justify-end mt-4">
              <Dialog.Close asChild>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => onSubmit(offset)}
                  autoFocus
                >
                  <FormattedMessage
                    description="Flight timing close button"
                    defaultMessage="Continuer"
                  />
                </Button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
