import { useQuery, QueryHookOptions } from '@apollo/client';
import { FetchHistoricAddressesQuery } from '@customer-booking/__generated__/graphql';
import gql from 'graphql-tag';
import React from 'react';

const FETCH_HISTORIC_ADDRESSES = gql`
  query FetchHistoricAddresses {
    customer {
      id @client
      profile {
        id
        historicAddresses {
          address
          default
          lat
          long
          name
          tag
          zipCode
          company {
            id
          }
        }
      }
    }
  }
`;

export const useHistoricAddresses = (
  count = 3,
  options: QueryHookOptions<FetchHistoricAddressesQuery> = {}
) => {
  const { data, ...query } = useQuery<FetchHistoricAddressesQuery>(
    FETCH_HISTORIC_ADDRESSES,
    options
  );

  const historicAddresses = React.useMemo(() => {
    const addresses = data?.customer?.profile?.historicAddresses || [];
    return addresses.slice(0, count);
  }, [data, count]);

  return { data: historicAddresses, ...query };
};
