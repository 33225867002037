import { ApolloError } from '@apollo/client';
import { FetchVehiclesTypesQuery } from '@customer-booking/__generated__/graphql';
import { createContext } from '@hermes/utils/context';

interface VehicleTypesListFieldContext {
  loading: boolean;
  error: ApolloError | undefined;
  vehiclesTypes: FetchVehiclesTypesQuery | undefined;
}

const [useVehicleField, VehicleFieldContextProvider] =
  createContext<VehicleTypesListFieldContext>();

export { useVehicleField, VehicleFieldContextProvider };
