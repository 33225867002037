import * as z from 'zod';
import { CalendarDate, Time } from '@internationalized/date';

import { todayLocalTime } from '@hermes/utils/date';
import { isSameLocation } from '@customer-booking/features/places';
import { IntlFormatters } from 'react-intl';

import { RideRequestType } from './RideForm.types';

export const addressSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  z.object(
    {
      name: z.string(),
      long: z.number().optional(),
      lat: z.number().optional(),
    },
    {
      invalid_type_error: formatMessage({
        description: 'Address type error',
        defaultMessage: 'Veuillez renseigner une adresse valide',
      }),
      required_error: formatMessage({
        description: 'Address type error',
        defaultMessage: 'Veuillez renseigner une adresse',
      }),
    }
  );

export const flightSchema = z.object({
  flightId: z.number(),
  flightCode: z.string(),
  flightNumber: z.string(),
  arrivalFsCode: z.string(),
  arrivalAirport: z.string(),
  arrivalCity: z.string(),
  departAirport: z.string(),
  departTime: z.string(),
  arrivalTime: z.string(),
  departCity: z.string(),
});

const dateTimeSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  z
    .object({
      rideType: z.nativeEnum(RideRequestType),
      time: z.instanceof(Time).optional(),
      date: z.instanceof(CalendarDate).optional(),
    })
    .refine(
      ({ date, rideType }) => {
        return rideType !== 'Asap' && date
          ? date.compare(todayLocalTime()) >= 0
          : true;
      },
      {
        message: formatMessage({
          description: 'Ride datetime 30m offset error',
          defaultMessage:
            'Veuillez réserver une course au moins 30 minutes en avance.',
        }),
        path: ['date'],
      }
    )
    // max 45 days from now
    .refine(
      ({ date, rideType }) => {
        return rideType !== 'Asap' && date
          ? date.compare(todayLocalTime().add({ days: 45 })) <= 0
          : true;
      },
      {
        message: formatMessage({
          description: 'Ride datetime 45 days offset error',
          defaultMessage:
            'Veuillez réserver une course au maximum 45 jours à l’avance.',
        }),
        path: ['date'],
      }
    );
export const vehicleTypeSchema = z.object({
  vehicleType: z.string(),
});

export const tripSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  z
    .object({
      pickUpAddress: addressSchema(formatMessage),
      dropOffAddress: addressSchema(formatMessage),
      stops: z
        .array(addressSchema(formatMessage).or(z.undefined()).or(z.null()))
        .optional(),
    })
    .refine(
      (val) => {
        const { pickUpAddress, dropOffAddress, stops } = val;

        const allAddresses = [
          pickUpAddress,
          ...(stops || []),
          dropOffAddress,
        ].filter(Boolean);

        for (let i = 0; i < allAddresses.length - 1; i++) {
          const addressA = allAddresses[i];
          const addressB = allAddresses[i + 1];

          if (addressA && addressB && isSameLocation(addressA, addressB)) {
            return false;
          }
        }

        return true;
      },
      {
        message: formatMessage({
          description: 'Same address error',
          defaultMessage: 'Veuillez renseigner des adresses différentes',
        }),
        path: ['trip'],
      }
    );

export const rideSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  dateTimeSchema(formatMessage).and(
    z.object({
      trip: tripSchema(formatMessage),
      flight: flightSchema.optional().nullable(),
      trainReference: z.string().optional(),
    })
  );

export const dashboardRideSchema = (
  formatMessage: IntlFormatters['formatMessage']
) =>
  rideSchema(formatMessage)
    .and(vehicleTypeSchema)
    .and(
      z.object({
        oldRequestId: z.number().optional(),
        options: z
          .array(
            z.object({
              id: z.number(),
            })
          )
          .optional(),
        comment: z.string().optional(),
        discountCode: z.string().optional(),
      })
    );
