export type RideDateTooSoonErrorType = {
  type: 'RIDE_DATE_TOO_SOON';
  prevRideDate: string;
  nextRideDate: string;
};

export type RideDateInvalidErrorType = {
  type: 'RIDE_DATE_INVALID';
  prevRideDate: string;
  nextRideDate: string;
};

export type UnknownErrorType = {
  type: 'UNKNOWN_ERROR';
  message?: string;
};

type SetLoading = {
  type: 'SET_LOADING';
  loading: boolean;
};

type Reset = {
  type: 'RESET';
};

export type UIStateActions =
  | RideDateTooSoonErrorType
  | RideDateInvalidErrorType
  | UnknownErrorType
  | SetLoading
  | Reset;

export interface UIState {
  feedback:
    | RideDateTooSoonErrorType
    | RideDateInvalidErrorType
    | UnknownErrorType
    | null;
  loading: boolean;
}

export const bookingUIStateReducer = (
  state: UIState,
  action: UIStateActions
): UIState => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'RIDE_DATE_TOO_SOON':
    case 'RIDE_DATE_INVALID':
    case 'UNKNOWN_ERROR':
      return {
        ...state,
        feedback: action,
      };
    case 'RESET':
      return {
        ...state,
        feedback: null,
        loading: false,
      };
    default:
      return state;
  }
};

export const loadingAction = (loading: boolean): SetLoading => ({
  type: 'SET_LOADING',
  loading,
});

export const resetAction = (): Reset => ({
  type: 'RESET',
});

export const rideDateTooSoonErrorAction = (
  prevRideDate: string,
  nextRideDate: string
): RideDateTooSoonErrorType => ({
  type: 'RIDE_DATE_TOO_SOON',
  prevRideDate,
  nextRideDate,
});

export const rideDateInvalidErrorAction = (
  prevRideDate: string,
  nextRideDate: string
): RideDateInvalidErrorType => ({
  type: 'RIDE_DATE_INVALID',
  prevRideDate,
  nextRideDate,
});

export const unknownErrorAction = (message?: string): UnknownErrorType => ({
  type: 'UNKNOWN_ERROR',
  message,
});

export const isRideError = (UIState: UIState) => {
  return (
    UIState.feedback?.type === 'RIDE_DATE_TOO_SOON' ||
    UIState.feedback?.type === 'RIDE_DATE_INVALID' ||
    UIState.feedback?.type === 'UNKNOWN_ERROR'
  );
};
export const isRideDateTooSoonError = (UIState: UIState) => {
  return UIState.feedback?.type === 'RIDE_DATE_TOO_SOON';
};
