import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ToggleButtonGroup } from '@hermes/ui';
import { RideRequestType } from './RideForm.types';

export const RideTypeField = ({
  onChange,
}: {
  onChange?: (rideType: RideRequestType) => void;
}) => {
  const { control } = useFormContext<{
    rideType: RideRequestType;
  }>();

  return (
    <div className="flex justify-center">
      <Controller
        name="rideType"
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup.Root
            onValueChange={(value) => {
              field.onChange(value as RideRequestType);
              onChange?.(value as RideRequestType);
            }}
            value={field.value}
          >
            <ToggleButtonGroup.Item
              name={field.name}
              value={RideRequestType.planned}
            >
              <FormattedMessage
                description="In advance booking form option"
                defaultMessage="Réserver à l'avance"
              />
            </ToggleButtonGroup.Item>
            <ToggleButtonGroup.Item
              name={field.name}
              value={RideRequestType.asap}
            >
              <FormattedMessage
                description="As soon as possible form option"
                defaultMessage="Dès que possible"
              />
            </ToggleButtonGroup.Item>
          </ToggleButtonGroup.Root>
        )}
      />
    </div>
  );
};
