import * as React from 'react';
import { isErrorType, isRideRequestDateError } from '../helpers';
import {
  CreateRideRequestQueryOptions,
  useCreateRideRequest as useCreateRideRequestQuery,
} from './../api/createRideRequest';
import {
  rideDateInvalidErrorAction,
  rideDateTooSoonErrorAction,
  unknownErrorAction,
  useBookingUIState,
} from '../context';

export type CreateRideRequestOption = CreateRideRequestQueryOptions;

export const useCreateRideRequest = (options?: CreateRideRequestOption) => {
  const { dispatch } = useBookingUIState();
  const dispatchRef = React.useRef(dispatch);

  const [mutation, { retryToNextTimeSlot, ...queryState }] =
    useCreateRideRequestQuery(options);

  const mutationWithRetry = React.useCallback(
    (options: CreateRideRequestQueryOptions) => {
      return mutation({
        ...options,
        onError: (error, clientOptions) => {
          options?.onError?.(error, clientOptions);

          if (clientOptions && isRideRequestDateError(error)) {
            retryToNextTimeSlot({
              ...clientOptions,
              onCompleted: (data, clientOptions) => {
                const action = isErrorType(error, ['RIDE_DATE_TOO_SOON'])
                  ? rideDateTooSoonErrorAction
                  : isErrorType(error, ['RIDE_DATE_INVALID'])
                  ? rideDateInvalidErrorAction
                  : undefined;

                if (action) {
                  dispatchRef.current(
                    action(
                      clientOptions?.variables?.input.rideDate as string,
                      data.createRideRequestWeb.pickUpDate
                    )
                  );
                }

                options?.onCompleted?.(data, clientOptions);
              },
              onError: (error) => {
                const message = (
                  error.graphQLErrors[0]?.extensions.response as {
                    body: { error: { message: string } };
                  }
                ).body.error.message;

                dispatchRef.current(unknownErrorAction(message));
              },
            });
          } else {
            const message = (
              error.graphQLErrors[0]?.extensions.response as {
                body: { error: { message: string } };
              }
            ).body.error.message;

            dispatchRef.current(unknownErrorAction(message));
          }
        },
      });
    },
    [mutation, retryToNextTimeSlot]
  );

  return [mutationWithRetry, queryState] as const;
};
