import * as React from 'react';

import { useAppStore } from '@customer-booking/features/store';
import { useCreateRideRequestFromStore } from './useCreateRideRequestFromStore';
import { AuthStatus } from '@customer-booking/features/auth';
import { ApolloError } from '@apollo/client';

export type SyncStatus = 'idle' | 'syncing' | 'fetching' | 'done' | 'error';

export const useSyncRideRequest = (
  authStatus: AuthStatus,
  customerProfile?: {
    id: number;
    firstName: string;
    lastName: string;
  }
) => {
  const [status, setStatus] = React.useState<SyncStatus>('idle');
  const [error, setError] = React.useState<ApolloError | undefined>();
  const [createRideRequest] = useCreateRideRequestFromStore();

  const [rideRequest] = useAppStore((state) => [state.rideRequest]);

  const shouldSyncRequest = Boolean(
    rideRequest &&
      !rideRequest.customer &&
      customerProfile &&
      rideRequest.state === 'Created'
  );

  React.useEffect(() => {
    if (!shouldSyncRequest) {
      setStatus('done');
    } else if (authStatus === 'fetching') {
      setStatus('idle');
    }
  }, [shouldSyncRequest, authStatus]);

  React.useEffect(() => {
    setStatus('syncing');

    if (!shouldSyncRequest) {
      setStatus('done');
      return;
    }

    createRideRequest(
      {},
      {
        onCompleted: () => {
          setStatus('done');
        },
        onError: (error) => {
          setStatus('error');
          setError(error);
        },
      }
    );
  }, [shouldSyncRequest, rideRequest, customerProfile, createRideRequest]);

  return { status, error };
};
