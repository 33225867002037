import * as React from 'react';
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { twMerge } from 'tailwind-merge';
import { FormattedMessage, useIntl } from 'react-intl';

import { Fieldset } from '@hermes/ui';
import { createContext } from '@hermes/utils/context';

import { RideFormValues, RideRequestType } from './RideForm.types';
import { DateFields } from './DateFields';
import { SubmitButton as SubmitButtonPrimitive } from './SubmitButton';
import { rideSchema, vehicleTypeSchema } from './rideSchema';
import { LegsFields } from './LegsFields';
import { useCurrentRideId } from '../../hooks';
import { VehicleTypesListField } from './VehicleTypesField/VehicleTypesField';
import { CalendarDate, Time } from '@internationalized/date';
import { RideTypeField } from './RideTypeField';

interface RideFormContext {
  loading: boolean;
  edit: boolean;
}

const [useRideForm, RideFormProvider] = createContext<RideFormContext>();

const TripLegend = () => {
  const pickUp = useWatch({ name: 'trip.pickUpAddress' });
  const dropOff = useWatch({ name: 'trip.dropOffAddress' });
  const flight = useWatch({ name: 'flight' });

  if (!pickUp) {
    return (
      <FormattedMessage
        description="Trip form pickup legend"
        defaultMessage="Où pouvons-nous vous prendre en charge ?"
      />
    );
  }

  if (pickUp.types?.includes('Airport') && !flight) {
    return (
      <FormattedMessage
        description="Trip form flight legend"
        defaultMessage="Quel est votre numéro de vol ?"
      />
    );
  }

  if (!dropOff) {
    return (
      <FormattedMessage
        description="Trip form dropoff legend"
        defaultMessage="Où pouvons-nous vous déposer ?"
      />
    );
  }

  return (
    <FormattedMessage
      description="Trip form summary legend"
      defaultMessage="Votre trajet"
    />
  );
};

interface RideFormProps {
  onSubmit: (values: RideFormValues) => void;
  defaultValues?: Partial<RideFormValues>;
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const RideFormRoot = ({
  defaultValues,
  onSubmit,
  loading = false,
  children,
  className,
}: RideFormProps) => {
  const rideId = useCurrentRideId();

  const hasVehicleTypeField = React.Children.toArray(children).some(
    (c) => React.isValidElement(c) && c.type === VehicleTypesField
  );

  const { formatMessage } = useIntl();

  const schema = React.useMemo(() => {
    if (hasVehicleTypeField) {
      return rideSchema(formatMessage).and(vehicleTypeSchema);
    }

    return rideSchema(formatMessage);
  }, [formatMessage, hasVehicleTypeField]);

  const methods = useForm<RideFormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      rideType: RideRequestType.planned,
      trip: {
        pickUpAddress: null,
        dropOffAddress: null,
        stops: [],
      },
      flight: null,
      ...defaultValues,
    },
  });

  const { handleSubmit } = methods;

  const bookingTitle = rideId
    ? formatMessage({
        description: 'Edit Booking header',
        defaultMessage: 'Modifier une course',
      })
    : formatMessage({
        description: 'New Booking header',
        defaultMessage: 'Réservez une course',
      });

  const formCtx = React.useMemo(
    () => ({
      loading,
      edit: Boolean(rideId),
    }),
    [loading, rideId]
  );

  return (
    <RideFormProvider value={formCtx}>
      <FormProvider {...methods}>
        <h1
          id="form-description"
          className="flex px-4 mb-2 text-primary justify-center text-2xl font-bold"
        >
          {bookingTitle}
        </h1>
        <form
          id="__rideForm"
          aria-labelledby="form-description"
          className={twMerge('flex flex-col gap-4', className)}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {children}
        </form>
      </FormProvider>
    </RideFormProvider>
  );
};

const DateField = ({
  className,
  ...props
}: {
  className?: string;
  onChange?: (values: { date?: CalendarDate; time?: Time }) => void;
}) => {
  const { watch } = useFormContext<RideFormValues>();
  const { formatMessage } = useIntl();
  const rideType = watch('rideType');

  if (rideType !== RideRequestType.planned) {
    return null;
  }

  return (
    <div className={twMerge('relative mb-4', className)}>
      <Fieldset
        className="gap-4"
        legend={formatMessage({
          description: 'Trip date section',
          defaultMessage: 'Quand voulez-vous partir ?',
        })}
      >
        <DateFields {...props} />
      </Fieldset>
    </div>
  );
};

const LegsField = () => {
  return (
    <Fieldset legend={<TripLegend />}>
      <LegsFields />
    </Fieldset>
  );
};

const VehicleTypesField = () => {
  const { formatMessage } = useIntl();

  return (
    <VehicleTypesListField.Root>
      <VehicleTypesListField.Fieldset
        className="mt-2"
        legend={formatMessage({
          description: 'Vehicle type section',
          defaultMessage: 'Nos véhicules',
        })}
      >
        <VehicleTypesListField.List />
      </VehicleTypesListField.Fieldset>
    </VehicleTypesListField.Root>
  );
};

interface SubmitButtonProps {
  visibility?: 'always' | 'valid';
  className?: string;
}

const SubmitButton = ({
  visibility = 'valid',
  className,
}: SubmitButtonProps) => {
  const { loading, edit } = useRideForm();
  return (
    <>
      <SubmitButtonPrimitive
        className={className}
        isLoading={loading}
        isEdit={edit}
        visibility={visibility}
      />
    </>
  );
};

export const RideForm = {
  Root: RideFormRoot,
  TypeField: RideTypeField,
  DateField,
  LegsField,
  VehicleTypesField,
  SubmitButton,
};
