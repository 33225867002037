import * as React from 'react';

import { RideRequest, useAppStore } from '@customer-booking/features/store';
import { MutationHookOptions } from '@apollo/client';
import {
  CreateRideRequestMutation,
  CreateRideRequestMutationVariables,
} from '@customer-booking/__generated__/graphql';
import { useAuth } from '@customer-booking/features/auth';

import { useCreateRideRequest } from './useCreateRideRequest';
import { useCurrentRideId } from './useCurrentRideId';
import { transformRideRequestToVariables } from '../helpers/transformRideRequestToVariables';

type Options = MutationHookOptions<
  CreateRideRequestMutation,
  CreateRideRequestMutationVariables
>;

export const useCreateRideRequestFromStore = (options?: Options) => {
  const rideId = useCurrentRideId();

  const { customerProfile } = useAuth();
  const [rideRequest, setRideRequest] = useAppStore((state) => [
    state.rideRequest,
    state.setRideRequest,
  ]);

  const [createRideRequest, queryData] = useCreateRideRequest(options);

  const mutation = React.useCallback(
    (
      rideUpdates:
        | Partial<CreateRideRequestMutationVariables['input']>
        | ((
            currentRideRequest: RideRequest | undefined
          ) => Partial<CreateRideRequestMutationVariables['input']>),
      { onCompleted, ...options }: Options = {}
    ) => {
      const updates =
        typeof rideUpdates === 'function'
          ? rideUpdates(rideRequest)
          : rideUpdates;

      const currentRideRequestVariables = rideRequest
        ? transformRideRequestToVariables(rideRequest)
        : undefined;

      const variables: CreateRideRequestMutationVariables =
        currentRideRequestVariables
          ? {
              input: {
                ...currentRideRequestVariables,
                oldRequestId: rideId ? Number(rideId) : undefined,
                passenger: currentRideRequestVariables.passenger
                  ? currentRideRequestVariables.passenger
                  : customerProfile?.customer.passengers.default
                  ? { id: customerProfile.customer.passengers.default.id }
                  : undefined,
                ...updates,
              },
            }
          : {
              input: updates as CreateRideRequestMutationVariables['input'],
            };

      return createRideRequest({
        ...options,
        variables,
        onCompleted: (data) => {
          setRideRequest(data.createRideRequestWeb);
          onCompleted?.(data);
        },
      });
    },
    [createRideRequest, rideRequest, customerProfile, setRideRequest, rideId]
  );

  return [mutation, queryData] as const;
};
