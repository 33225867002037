import { ApolloError } from '@apollo/client';
import { RideRequestState } from '@customer-booking/__generated__/graphql';
import { sanitizeTime } from '@hermes/ui';
import { nowLocalTime } from '@hermes/utils/date';
import { Time } from '@internationalized/date';
import { RIDE_REQUEST_TIME_OFFSET } from '../constants';
import { RideRequestFailedState } from '../types';

export const isRideRequestBookingFailed = (
  status: RideRequestState
): status is RideRequestFailedState => {
  switch (status) {
    case 'Impossible':
    case 'NoDriverForAdvanceRide':
    case 'NoDriverForLiveRide':
    case 'OutsourceFailed':
    case 'PaymentFailed':
      return true;
    default:
      return false;
  }
};

export const isRideRequestConfirmed = (status: RideRequestState) => {
  if (isRideRequestBookingFailed(status)) {
    return false;
  }

  switch (status) {
    case 'Created':
    case 'BeingOutsourced':
    case 'BeingDispatched':
    case 'BeingAuthorized':
    case 'Authorized':
      return false;
    default:
      return true;
  }
};

export const isErrorType = (error: ApolloError, errorCode: string[]) => {
  const errorCodes = error.graphQLErrors.flatMap(
    (error) =>
      (error.extensions?.errorCodes as string[]).map(
        (code) => code as string
      ) || []
  );

  return errorCodes.some((code) => errorCode.includes(code));
};

export const isRideRequestDateError = (error: ApolloError) =>
  isErrorType(error, ['RIDE_DATE_TOO_SOON', 'RIDE_DATE_INVALID']);

export const getFirstTimeSlotForRide = (factor = 0) => {
  const datetime = nowLocalTime();

  const nextTimeSlot = sanitizeTime(
    new Time(datetime.hour, datetime.minute).add({
      minutes: RIDE_REQUEST_TIME_OFFSET + 10 * factor,
    })
  );

  return nextTimeSlot;
};
