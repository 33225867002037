import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Callout, Dialog } from '@hermes/ui';

import {
  RideDateInvalidErrorType,
  RideDateTooSoonErrorType,
  UnknownErrorType,
} from '../context/bookingUIStateReducer';

const RideDateTooSoon = ({
  nextRideDate,
}: {
  prevRideDate: string;
  nextRideDate: string;
}) => {
  return (
    <>
      <Dialog.Title>
        <FormattedMessage
          description="Ride date too soon error title"
          defaultMessage="La date de la course est trop proche"
        />
      </Dialog.Title>
      <Callout className="my-4">
        <FormattedMessage
          description="Ride date too soon error description"
          defaultMessage="Vous ne pouvez pas planifier une course à moins une demie heure de l'heure actuelle. Nous avons modifié l'heure de la course à <strong>{rideDate, time, short}</strong>"
          values={{
            rideDate: new Date(nextRideDate),
            strong: (chunks) => <strong>{chunks as React.ReactNode}</strong>,
          }}
        />
      </Callout>
    </>
  );
};

const RideDateInvalid = ({
  nextRideDate,
}: {
  prevRideDate: string;
  nextRideDate: string;
}) => {
  return (
    <>
      <Dialog.Title>
        <FormattedMessage
          description="Ride date invalid error title"
          defaultMessage="La date de la course est invalide"
        />
      </Dialog.Title>
      <Callout className="my-4">
        <FormattedMessage
          description="Ride date invalid error description"
          defaultMessage="Vous ne pouvez pas planifier une course dans le passé. Nous avons modifié l'heure de la course à <strong>{rideDate, time, short}</strong>"
          values={{
            rideDate: new Date(nextRideDate),
            strong: (chunks) => (
              <strong className="text-lg">{chunks as React.ReactNode}</strong>
            ),
          }}
        />
      </Callout>
    </>
  );
};

const UnknownError = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Dialog.Title>
        <FormattedMessage
          description="Unknown error title"
          defaultMessage="Erreur lors de la création de la course"
        />
      </Dialog.Title>
      <Callout className="my-4">{children}</Callout>
    </>
  );
};

const Content = ({
  feedback,
}: {
  feedback:
    | RideDateTooSoonErrorType
    | RideDateInvalidErrorType
    | UnknownErrorType;
}) => {
  switch (feedback.type) {
    case 'RIDE_DATE_TOO_SOON': {
      const { type, ...data } = feedback;
      return <RideDateTooSoon {...data} />;
    }
    case 'RIDE_DATE_INVALID': {
      const { type, ...data } = feedback;
      return <RideDateInvalid {...data} />;
    }
    case 'UNKNOWN_ERROR': {
      const { type, ...data } = feedback;
      return (
        <UnknownError>
          {data.message ?? (
            <FormattedMessage
              description="Unknown error while creating ride request"
              defaultMessage="Une erreur inattendue est survenue lors de la création de la course"
            />
          )}
        </UnknownError>
      );
    }
    default:
      console.error('Unknown error while creating ride request');
      return null;
  }
};

interface RideRequestCreateFeedbackDialogProps {
  feedback:
    | RideDateTooSoonErrorType
    | RideDateInvalidErrorType
    | UnknownErrorType
    | null;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const RideRequestCreateFeedbackDialog = ({
  feedback,
  onOpenChange,
  open,
  ...props
}: RideRequestCreateFeedbackDialogProps) => {
  return (
    <Dialog.Root {...props} open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay>
          <Dialog.Content>
            <Dialog.CloseButton />
            {feedback && <Content feedback={feedback} />}
            <div className="flex justify-end mt-4">
              <Dialog.Close asChild>
                <Button autoFocus>
                  <FormattedMessage
                    description="Ride request create error dialog button"
                    defaultMessage="Continuer"
                  />
                </Button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
