import {
  now,
  today,
  isToday,
  DateValue,
  Time,
  CalendarDate,
  CalendarDateTime,
  parseAbsolute,
  parseDate,
  parseTime,
} from '@internationalized/date';

export const createCalendarDate = (year: number, month: number, day: number) =>
  new CalendarDate(year, month, day);

export const createCalendarDateTime = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number
) => new CalendarDateTime(year, month, day, hour, minute, second);

const PARIS_TIME_ZONE = 'Europe/Paris';

export const todayLocalTime = (timezone = PARIS_TIME_ZONE) => today(timezone);
export const nowLocalTime = (timezone = PARIS_TIME_ZONE) => now(timezone);
export const isTodayLocalTime = (date: DateValue, timezone = PARIS_TIME_ZONE) =>
  isToday(date, timezone);

export const time = () => {
  const datetime = nowLocalTime();
  return new Time(datetime.hour, datetime.minute);
};

export const createTime = (hour: number, minute: number) =>
  new Time(hour, minute);

export const parseAbsoluteDate = (date: Date, timezone = PARIS_TIME_ZONE) => {
  return parseAbsolute(date.toISOString(), timezone);
};

export const timeToDate = (time: Time, timezone = PARIS_TIME_ZONE) => {
  const now = nowLocalTime();
  return createCalendarDateTime(
    now.year,
    now.month,
    now.day,
    time.hour,
    time.minute,
    0
  ).toDate(timezone);
};

export const parseToCalendarDate = (date: string) => {
  const [dateStr] = date.replace('Z', '').split('T');
  return parseDate(dateStr);
};

export const parseToTime = (date: string) => {
  const [, timeStr] = date.replace('Z', '').split('T');
  return parseTime(timeStr);
};
