import * as React from 'react';
import { useIntl } from 'react-intl';

import {
  Spinner as UISpinner,
  SpinnerProps as UISpinnerProps,
} from '@hermes/ui';

export interface SpinnerProps extends Omit<UISpinnerProps, 'text'> {
  text?: string;
}

export const Spinner = (props: SpinnerProps) => {
  const { formatMessage } = useIntl();

  return (
    <UISpinner
      text={formatMessage({
        description: 'Loading text',
        defaultMessage: 'Chargement...',
      })}
      {...props}
    />
  );
};
