import {
  CreateRideRequestMutation,
  RideRequestInput,
} from '@customer-booking/__generated__/graphql';
import * as R from 'remeda';

export const transformRideRequestToVariables = (
  rideRequest: CreateRideRequestMutation['createRideRequestWeb']
): RideRequestInput => ({
  channel: 'Website' as const,
  type: rideRequest.type,
  requestCompanyReferences: rideRequest.requestCompanyReferences.map(
    ({ companyReferenceId, value }) => ({
      companyReferenceId,
      value,
    })
  ),
  vehicleType: { id: rideRequest.vehicleType.id },
  options: rideRequest.options?.map((option) => ({
    id: option.id,
  })),
  flightId: rideRequest.flight?.flightId,
  flightRef: rideRequest.flight?.flightRef,
  passenger: rideRequest.passenger
    ? { id: rideRequest.passenger.id }
    : undefined,
  discountCode: rideRequest.discountCode,
  comment: rideRequest.comment,
  rideDate: rideRequest.type === 'Asap' ? undefined : rideRequest.pickUpDate,
  companyId: rideRequest.companyId,
  customer: rideRequest.customer ? { id: rideRequest.customer.id } : undefined,
  addressPickUp: R.omit(rideRequest.addressPickUp, ['__typename']),
  addressDropOff: rideRequest.addressDropOff
    ? R.omit(rideRequest.addressDropOff, ['__typename'])
    : undefined,
  stops: rideRequest.stops?.map(({ __typename, id, ...stop }) => ({
    ...stop,
    address: stop.name,
  })),
});
