import * as React from 'react';
import {
  useMutation,
  MutationHookOptions,
  BaseMutationOptions,
  DefaultContext,
  OperationVariables,
} from '@apollo/client';

import { gql } from '@customer-booking/__generated__';
import {
  CreateRideRequestMutationVariables,
  CreateRideRequestMutation,
} from '@customer-booking/__generated__/graphql';
import { useLocale } from '@customer-booking/features/l10n';
import { getFirstTimeSlotForRide } from '../helpers';

export const CREATE_RIDE_REQUEST = gql(/* GraphQL */ `
  mutation CreateRideRequest($input: RideRequestInput!) {
    createRideRequestWeb(input: $input) {
      id
      companyId
      co2
      customer {
        id
        companies {
          id
        }
      }
      addressDropOff {
        name
        long
        lat
      }
      stops {
        id
        name
        long
        lat
      }
      createdAt
      addressPickUp {
        name
        long
        lat
      }
      companyRideDiscountPercentage
      companyRideDiscount
      ridePriceBeforeCompanyDiscount
      discountCode
      discountCodeDiscount
      pickUpDate
      dropOffDate
      isFirstRide
      timeBeforePickUp
      timeBeforeDropOff
      distance
      distanceUnit
      price
      flight {
        arrivalAirport
        arrivalCity
        departAirport
        departTime
        flightRef
        arrivalTime
        departCity
        flightId
        flightCode
        flightNumber
        arrivalFsCode
        arrivalLat
        arrivalLong
        arrivalTerminal
      }
      comment
      customerPrice
      customerPriceBeforeCredit
      currency {
        symbol
        name
      }
      type
      state
      duration
      requestCompanyReferences {
        id
        companyReferenceId
        value
      }
      vehicleType {
        id
        name
        logo
        maxPlaces
        maxLuggages
        designation
        mapPictoUrl
        tagline
      }
      passenger {
        id
        firstName
        lastName
      }
      taxIncludedMessage
      options {
        id
        name
        price
      }
    }
  }
`);

const MAX_RETRY = 3;

export type CreateRideRequestQueryOptions = MutationHookOptions<
  CreateRideRequestMutation,
  CreateRideRequestMutationVariables,
  Record<string, unknown>
>;

export const useCreateRideRequest = (
  options?: CreateRideRequestQueryOptions
) => {
  const retry = React.useRef(0);
  const { locale } = useLocale();

  const [mutation, { reset, ...rest }] = useMutation<
    CreateRideRequestMutation,
    CreateRideRequestMutationVariables,
    Record<string, unknown>
  >(CREATE_RIDE_REQUEST, {
    ...options,
    context: {
      ...options?.context,
      headers: {
        'Accept-Language': `${locale};q=1.0`,
      },
    },
  });

  const retryToNextTimeSlot = React.useCallback(
    (
      options: BaseMutationOptions<
        CreateRideRequestMutation,
        OperationVariables,
        DefaultContext
      >
    ) => {
      retry.current = retry.current + 1;
      if (retry.current > MAX_RETRY) return;

      reset();

      const [currentDate] =
        options?.variables?.input?.rideDate.split('T') ?? [];

      const nextRideSlot = new Date(
        `${currentDate.toString()}T${getFirstTimeSlotForRide(
          retry.current - 1
        ).toString()}Z`
      ).toISOString();

      mutation({
        ...options,
        variables: options?.variables?.input && {
          ...options?.variables,
          input: {
            ...options?.variables?.input,
            rideDate: nextRideSlot,
          },
        },
        onCompleted: (data) => {
          retry.current = 0;
          options?.onCompleted?.(data);
        },
        onError: (error, clientOptions) => {
          if (retry.current === MAX_RETRY) {
            options?.onError?.(error, clientOptions);
          } else {
            retryToNextTimeSlot(options);
          }
        },
      });
    },
    [reset, mutation]
  );

  const mutate = React.useCallback(
    (options: CreateRideRequestQueryOptions) => {
      retry.current = 0;
      return mutation(options);
    },
    [mutation]
  );

  return [mutate, { reset, retryToNextTimeSlot, ...rest }] as const;
};
