import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  SearchPlacesQuery,
  SearchPlacesQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useLocale } from '@customer-booking/features/l10n';

export const SEARCH_PLACES = gql(/* GraphQL */ `
  query SearchPlaces($input: String!, $types: [PlaceAutocompleteType!]) {
    place {
      autocomplete(input: $input, types: $types) {
        id
        primary
        secondary
        types
      }
    }
  }
`);

export const useSearchPlaces = (
  variables: SearchPlacesQueryVariables,
  options?: Omit<
    QueryHookOptions<SearchPlacesQuery, SearchPlacesQueryVariables>,
    'variables'
  >
) => {
  const { locale } = useLocale();

  return useQuery(SEARCH_PLACES, {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        'Accept-Language': `${locale};q=1.0`,
      },
    },
    variables,
  });
};
