import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { AirplaneIcon } from '@hermes/icons/simple';

interface FlightDetailsProps {
  flight: FlightType;
  className?: string;
}

export interface FlightType {
  arrivalAirport: string;
  departAirport: string;
  departTime: string;
  arrivalTime: string;
}

export const FlightDetails: React.FC<FlightDetailsProps> = ({
  flight,
  className,
}) => {
  return (
    <div
      className={twMerge('flex w-full flex-col gap-4 text-primary', className)}
    >
      <strong className="font-bold text-base whitespace-nowrap">
        <FormattedMessage
          description="Flight arrival date"
          defaultMessage="Arrivée prévue le {date, date, ::d MMM}"
          values={{
            date: new Date(flight.arrivalTime),
          }}
        />
      </strong>
      <div className="flex gap-1">
        <div className="flex flex-col text-left grow w-full">
          <strong className="font-bold text-base">
            <FormattedMessage
              description="Flight departure time"
              defaultMessage="{date, time, ::HH mm}"
              values={{
                date: new Date(flight.departTime),
              }}
            />
          </strong>
          <span>{flight.departAirport}</span>
        </div>
        <div className="grow w-full flex items-center justify-center gap-1">
          <div className="flex-grow border-b border-primary" />
          <AirplaneIcon className="size-6 rotate-90 fill-primary" />
          <div className="flex-grow border-b border-primary" />
        </div>
        <div className="flex flex-col text-right grow w-full">
          <strong className="font-bold text-base">
            <FormattedMessage
              description="Flight arrival time"
              defaultMessage="{date, time, ::HH mm}"
              values={{
                date: new Date(flight.arrivalTime),
              }}
            />
          </strong>
          <span>{flight.arrivalAirport}</span>
        </div>
      </div>
    </div>
  );
};
