import { useGetPosition } from '@hermes/utils/hooks';

import { FetchPlaceDetailsFromLatLongQuery } from '@customer-booking/__generated__/graphql';

import { useFetchPlaceDetailsFromLatLong } from './../api/fetchPlaceDetailsFromLatLong';

export const useCurrentPlace = () => {
  const { getPosition, loading, error: geoLocError } = useGetPosition();

  const [fetchPlaceDetails, { error, data, loading: detailsLoading }] =
    useFetchPlaceDetailsFromLatLong();

  const getPositionWithPlace = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (
      place:
        | FetchPlaceDetailsFromLatLongQuery['place']['detailsFromLatLong']
        | undefined
    ) => void;
    onError?: (error: Error) => void;
  }) => {
    if (data) {
      onSuccess?.(data?.place?.detailsFromLatLong);
      return;
    }

    getPosition({
      onSuccess: async (position) => {
        const { data, error } = await fetchPlaceDetails({
          variables: {
            input: {
              lat: position[0],
              long: position[1],
            },
          },
        });

        if (error) {
          onError?.(error);
          return;
        }

        onSuccess?.(data?.place?.detailsFromLatLong);
      },
    });
  };

  return {
    error: error || geoLocError,
    loading: loading || detailsLoading,
    getPosition: getPositionWithPlace,
  };
};
